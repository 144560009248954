<template>
  <div class="orders">
    <Spinner :is-loading="!orderPoints" />
    <SubHeaderBlock />
    <FilterBlock />
    <div class="container">
      <TableBlock class="costs__table" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../../components/spinner/index.vue";
export default {
  components: {
    TableBlock: () => ({
      component: import("./components/table"),
    }),
    FilterBlock: () => ({
      component: import("../../components/filter/filter"),
    }),
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    Spinner,
  },
  computed: {
    ...mapState(["orderPoints"]),
  },
};
</script>

<style scoped>
.costs__table {
  margin-top: 20px;
}
.spinner {
  top: 50%;
}
</style>
